<template>
  <div>
    <div class="row">
      <b-col md="3"></b-col>
      <div class="col-md-6">
        <app-input
            :label="$t('label.name')"
            v-model="form.name"
            :placeholder="$t('placeholder.enter_name')"
            read-only
            disabled
        />
      </div>
    </div>
    <div class="row">
      <b-col md="3"></b-col>
      <div class="col-md-6">
        <app-input
            :label="$t('label.last_name')"
            v-model="form.last_name"
            :placeholder="$t('placeholder.enter_last_name')"
            read-only
            disabled
        />
      </div>
    </div>
    <div class="row">
      <b-col md="3"></b-col>
      <div class="col-md-6">
          <app-input
              :label="$t('label.email')"
              v-model="form.email"
              :placeholder="$t('placeholder.enter_email')"
              read-only
              disabled
          />
      </div>
    </div>
    <div class="row">
      <b-col md="3"></b-col>
      <div class="col-md-6">
        <app-multiselect
            :label="$t('label.role')"
            :placeholder="$t('placeholder.select_role')"
            :value="computedRoleValues"
            :options="rolesList"
            multiple
            @select="form.roles.push($event.id)"
            @remove="form.roles = form.roles.filter(el => el !== $event.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AppInput from '@/components/form-groups/AppInput';
import AppMultiselect from '@/components/form-groups/AppMultiselect';
import {mapGetters} from 'vuex';

export default {
  components: {
    AppInput,
    AppMultiselect
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  beforeMount() {
    this.$store.dispatch('rolesStore/GET_ROLES')
  },
  data: () => ({
    form: {
      name: '',
      last_name: '',
      email: '',
      roles: ''
    }
  }),
  computed: {
    ...mapGetters({
      rolesList: 'rolesStore/ROLES'
    }),
    computedRoleValues() {
      return this.rolesList.filter(el => this.form.roles.includes(el.id));
    }
  },
  watch: {
    form: {
      handler(data) {
        this.$emit('change', data);
      },
      deep: true
    },
    user(data) {
      Object.assign(this.form, data);
    }
  }
}
</script>