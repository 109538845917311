<template>
  <div>
    <app-card>
      <template #body>
        <user-form :user="user" @change="form = $event"/>
      </template>
    </app-card>

    <portal to="subheader-toolbar">
      <b-button class="mr-3" variant="transparent-white" @click="$router.push({name: 'usersList'})">
        {{ $t('btn.back_to_list') }}
      </b-button>
      <b-button variant="success" @click="update">
        {{ $t('btn.update') }}
      </b-button>
    </portal>
  </div>
</template>

<script>
import AppCard from '@/components/AppCard';
import UserForm from './components/UserForm';
import {mapGetters} from 'vuex';

export default {
  components: {
    AppCard,
    UserForm
  },
  data: () => ({
    userID: null,
    form: null
  }),
  beforeMount() {
    this.userID = this.$route.params.id;
    this.$store.dispatch('usersStore/GET_USER', this.userID);
  },
  computed: {
    ...mapGetters({
      user: 'usersStore/USER'
    }),
    computedTitle() {
      return this.user ? `${this.user.name} ${this.user.last_name}` : '...'
    }
  },
  methods: {
    update() {
      this.$store.dispatch('usersStore/UPDATE', {id: this.user.id, data: this.form}).then(() => {
        this.$router.push({name: 'usersList'});
      });
    }
  }
}
</script>